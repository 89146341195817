export const suggestionPng = {"src":"/static/suggestion.png","height":240,"width":316,"alt":""}
export const serveWinePng = {"src":"/static/serve-wine.png","height":445,"width":301,"alt":""}
export const quizTeaserPng = {"src":"/static/quiz-teaser.png","height":250,"width":234,"alt":""}
export const edvinPng = {"src":"/static/edvin.png","height":136,"width":188,"alt":""}
export const crashPng = {"src":"/static/crash.png","height":256,"width":296,"alt":""}
export const beratungTeaserPng = {"src":"/static/beratung-teaser.png","height":231,"width":270,"alt":""}
export const quizZuckerPng = {"src":"/static/quiz/zucker.png","height":190,"width":217,"alt":""}
export const quizZitronePng = {"src":"/static/quiz/zitrone.png","height":104,"width":196,"alt":""}
export const quizSalzPng = {"src":"/static/quiz/salz.png","height":169,"width":177,"alt":""}
export const quizPilzePng = {"src":"/static/quiz/pilze.png","height":140,"width":177,"alt":""}
export const quizPersonaWhitePng = {"src":"/static/quiz/persona-white.png","height":170,"width":59,"alt":""}
export const quizPersonaRedPng = {"src":"/static/quiz/persona-red.png","height":170,"width":74,"alt":""}
export const quizKaffeePng = {"src":"/static/quiz/kaffee.png","height":153,"width":173,"alt":""}
export const quizFruchtePng = {"src":"/static/quiz/fruchte.png","height":155,"width":207,"alt":""}
export const quizAlkoholPng = {"src":"/static/quiz/alkohol.png","height":195,"width":100,"alt":""}
export const adviceZutatenPng = {"src":"/static/advice/zutaten.png","height":171,"width":164,"alt":""}
export const adviceWelchenWeinstilPng = {"src":"/static/advice/welchen-weinstil.png","height":208,"width":218,"alt":""}
export const adviceWeintypPng = {"src":"/static/advice/weintyp.png","height":214,"width":134,"alt":""}
export const advicePersonlicherAnlassPng = {"src":"/static/advice/personlicher-anlass.png","height":210,"width":193,"alt":""}
export const adviceLandschaftPng = {"src":"/static/advice/landschaft.png","height":184,"width":298,"alt":""}
export const adviceGeschenkPng = {"src":"/static/advice/geschenk.png","height":201,"width":169,"alt":""}
export const adviceFurWelchenAnlassPng = {"src":"/static/advice/fur-welchen-anlass.png","height":157,"width":219,"alt":""}
export const adviceFlascheninhaltPng = {"src":"/static/advice/flascheninhalt.png","height":191,"width":135,"alt":""}
export const adviceBudgetPng = {"src":"/static/advice/budget.png","height":124,"width":141,"alt":""}
export const adviceBeratungPng = {"src":"/static/advice/beratung.png","height":256,"width":296,"alt":""}
export const adviceAnlassFamilieFreundePng = {"src":"/static/advice/anlass-familie-freunde.png","height":167,"width":264,"alt":""}